jQuery(document).ready(function($) {

  ///////////////////////////////////////////////
  // ONSCROLL

  const onscrollAnimations = document.querySelectorAll('.animate-onscroll');

  if (onscrollAnimations != null) {

    onscrollAnimations.forEach((animationElement) => {

      var observer = new IntersectionObserver((entries) => {
        animationObserverCallback(entries, observer, animationElement);
        }, 
        { threshold: 0.15 });
        observer.observe(animationElement);
    });
  }

  // let animationDelay = 500;
  // let delayAdd = 300;
  let entryPosition;

  const animateSequence = (entry, animationDelay) => {
    setTimeout(() => {
      entry.target.classList.add('active');
    }, animationDelay * entry.target.dataset.sequence);
  };

  const animateSingle = (entry) => {
    let animationStartPoint = 2 * (window.innerHeight/3);
    let entryPosition = entry.target.getBoundingClientRect();

    if (entryPosition.top < animationStartPoint) {
      entry.target.classList.add('active');
    }

    window.addEventListener('scroll', (e) => {
      entryPosition = entry.target.getBoundingClientRect();
      if (entryPosition.top < animationStartPoint) {
        setTimeout(() => {
          entry.target.classList.add('active');
        }, animationDelay);
      }
    });
  };

  const animateUndo = (entry, animationDelay) => {
    setTimeout(() => {
      entry.target.classList.remove('active');
    }, animationDelay);
  };
  
  const animationObserverCallback = (entries, observer, element) => {
    entries.forEach((entry, animatingElement) => {
      
      if (entry.isIntersecting) {
        
        // special for letter animating
        if (entry.target.classList.contains('animate-blockquote')) {
          let letters = entry.target.querySelectorAll('span');
          let letterDelay = 30;
          let bordersDelay = letters.length * letterDelay;

          letters.forEach((letter) => {
            setTimeout(() => {
              letter.classList.add('active');
            }, letterDelay);
            letterDelay = letterDelay+30;
          });

          setTimeout(() => {
            entry.target.classList.add('active');
          }, bordersDelay);

        } else if (entry.target.dataset.sequence != '') {
          animateSequence(entry, 100);

        } else {
          animateSingle(entry);
        }

      } else {

        if (entry.target.classList.contains('animate-undo')) {
          animateUndo(entry, 300);
        }
      }
    });
  };

  ///////////////////////////////////////////////////
  // HOVER
  
  // const hoverAnimations = document.querySelectorAll('.onhover');

  // if (hoverAnimations != null) {

  //     hoverAnimations.forEach((hoverAnimation) => {
  //         hoverAnimation.addEventListener('mouseover', () => {
  //             hoverAnimation.classList.add('active');
  //             setTimeout(() => {
  //                 hoverAnimation.classList.remove('active');
  //             }, 500);
  //         });
  //     });
  // }

  // Tickers
  const tickers = document.querySelectorAll('.uci-ticker');
  let tickerWidth;
  if (tickers.length) {

    tickers.forEach((ticker) => {
      tickerWidth = ticker.parentNode.parentNode.scrollWidth;
      ticker.style.setProperty('--ticker-width', (0 - tickerWidth) + 'px');
    });
  }
});